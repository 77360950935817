// Here you can add other styles
.gof-loading-overlay {
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);

    .gof-spinner {
        position: fixed;
        top: 40vh;
        right: 0;
        left: 0;
    }
}
.storeviews button{
    margin-left: 10px;
}
.c-header-nav {
    min-width: 160px;
}

.comma-sep {
    &:not(:last-of-type)::after {
        content: ', ';
    }
}

.no-height {
    height: 0;
    padding: 0;

    * {
        height: 0 !important;
        padding: 0 !important;
        //visibility: hidden !important;
        border: 0 !important;
        //display: none;
    }
}


.mw-70 {
    min-width: 70px !important;
}

.mw-100 {
    min-width: 100px !important;
}

.icon-aligner {
    vertical-align: text-bottom;
}

.w-65 {
    max-width: 65vw;
}

.cursor-pointer {
    cursor: pointer;
}

.list-group-item.active {
    background-color: #d8dbe0;
    border-color: #d8dbe0;
}

.pt-6p {
    padding-top: 6px
}

.oi-title {
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;

    border-top: 1px solid #333;

    &:first-child {
        border: 0;
    }
}

.retail-stores {
    .btn-link {
        color: blue;
    }


    td:nth-child(6) {

      

        input:not(:placeholder-shown) { 
            background: rgba(0,0,0, .2);
        }

        input[value=''] {
            background: none;
        }

    }

}

.finished-jobs-loading {
    position: relative;

    .loading-shading {
        top: 0;
        position: fixed;
        z-index: 999999;
        width: 100%;
        height: 100%;
    }

}

.switch-label {
    position: relative;
    top: -8px;
    margin-right: 5px;
}


// @media(max-width: 1600px) {

//     .mobile-order-1 {
//         order: 1;
//     }

//     .mobile-order-2 {
//         order: 2;
//     }

//     .mobile-order-3 {
//         order: 3;
//     }
// }


.table-responsive {
    tr.active {
        td {
            background: lightyellow;
        }
    }
}

.c-header-nav-item {
    .c-switch-slider {
        width: 58px;
        &::before {
            left: 12px;
        }
    }
}

.local-tz {
    .c-switch-slider {
        width: 65px;
        &::before {
            left: 3px;
        }
        &::after {
            right: 3px !important;
        }
    }
}

.order-jobs-log-table-status-heading {
    @media screen and (max-width: 768px) {
        width: 31%;
    }
}

.gof-notifications {
    position: relative;
    max-height: 500px;
    overflow-y: scroll;

    &-item-last {
        position: sticky;
        bottom: -8px;
        background-color: #fff;
        border-top: 1px solid #efefef;
    }
}

.btn-dimmed-success {
    color: #000;
    background-color: #75e099;
}

.card-header.dx-g-bs4-toolbar .form-control.w-25 { 
    @media screen and (max-width: 600px) {
        width: 100% !important;
    }
}

.rs-picker-daterange-menu.rs-picker-menu {
    z-index: 999;
}

.width50 {
    width: 50px;
}

.width30 {
    width: 20px;
}

.smaller-type {
    font-size: 0.8em;
}

.maxwidth {
    width: 40%;
}